import "./bootstrap";

import {
    createApp,
    h
} from "vue";
import {
    resolvePageComponent
} from "laravel-vite-plugin/inertia-helpers";
import {
    createInertiaApp,
    Head,
    Link
} from "@inertiajs/inertia-vue3";
import {
    InertiaProgress
} from "@inertiajs/progress";

import TextClamp
    from "vue3-text-clamp";
import InlineSvg
    from "vue-inline-svg";
import Vue3TouchEvents
    from "vue3-touch-events";
import Vue3EasyDataTable
    from "vue3-easy-data-table";
import Datepicker
    from "@vuepic/vue-datepicker";

import dayjs
    from "dayjs";
import dayJsPluginRelativeTime
    from "dayjs/plugin/relativeTime";
import dayJsPluginUtc
    from "dayjs/plugin/utc";
import dayJsPluginTimezone
    from "dayjs/plugin/timezone";
import dayJsPluginAdvancedFormat
    from "dayjs/plugin/advancedFormat";

dayjs.extend(dayJsPluginRelativeTime);
dayjs.extend(dayJsPluginUtc);
dayjs.extend(dayJsPluginTimezone);
dayjs.extend(dayJsPluginAdvancedFormat);

import { i18nVue } from 'laravel-vue-i18n'

const appName = import.meta.env.VITE_APP_NAME || "Classward";

import.meta.glob(["../images/**", "../fonts/**"]);

import tailwindColors
    from "../../colors";

window.colors = tailwindColors;

import {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem
} from "@headlessui/vue";

let app = createInertiaApp({
    title: (title) => `${title}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue", { eager: false })
        ),
    setup ({
        el,
        app,
        props,
        plugin
    }) {
        let inertiaApp = createApp({ render: () => h(app, props) });

        inertiaApp.config.globalProperties.dayjs = dayjs;
        inertiaApp.config.globalProperties.colors = tailwindColors;

        inertiaApp
            .use(plugin)
            .use(TextClamp)
            .use(Vue3TouchEvents)
            .use(i18nVue, {
                resolve: async lang => {
                    const langs = import.meta.glob('../../lang/*.json');
                    return await langs[`../../lang/php_${lang}.json`]();
                }
            })
            .mixin({ methods: { route } })
            .mixin({
                methods: {
                    getImageUrl (name, ext) {
                        return new URL(`../images/${name}`, import.meta.url)
                            .href;
                    }
                }
            })
            // third party components
            .component("inline-svg", InlineSvg)
            .component("EasyDataTable", Vue3EasyDataTable)
            .component("Head", Head)
            .component("Link", Link)
            .component("Datepicker", Datepicker)
            .component("Menu", Menu)
            .component("MenuButton", MenuButton)
            .component("MenuItems", MenuItems)
            .component("MenuItem", MenuItem);

        inertiaApp.mount(el);

        return inertiaApp;
    }
});

InertiaProgress.init({
    delay: 0,
    color: "#DD0000",
    includeCSS: true,
    showSpinner: true
});
