import lodash
    from "lodash";

window._ = lodash;


import Axios
    from "axios";

window.axios = Axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';



