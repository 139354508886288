// Description: Colors for the application
export default {
    transparent: "transparent",
    black: "#171C29",
    white: "#FFF",
    text: "#1B1B1B",
    primary: {
        lightest: '#CEDFDF',
        lighter: '#9CBCAC',
        light: '#45767C',
        default: '#01424B',
        dark: '#0A4957',
        darker: '#0A4957',
        darkest: '#0A4957',
    },
    secondary: {
        lightest: '#e0ada4',
        lighter: '#e17b69',
        light: '#ce1d1d',
        default: '#C20000',
        dark: '#740000',
        darker: '#8f3322',
        darkest: '#652316',
    },
    accent1: {
        light: '#F4EFFF',
        default: '#a318ad',
        dark: '#790284',
    },
    accent2: {
        light: "#fcf3eb",
        default: "#d96c29",
        dark: "#af4d12",
    },
    accent3: {
        light: "#e7f5f1",
        default: "#129777",
        dark: "#0c735a",
    },
    gray: {
        50: "#F6F7FC",
        100: "#F1F3F8",
        200: "#E7EAF0",
        300: "#DDE1EA",
        400: "#CBCED7",
        500: "#B1B5C3",
        600: "#767C8D",
        700: "#5D6375",
        800: "#3E3F40",
        900: "#171C29",
    },
    success: {
        lightest: "#C9FBCE",
        lighter: "#A7EBC2",
        light: "#98CFD0",
        default: "#089B81",
        dark: "#065546",
        darker: "#10542B",
    },
    warning: {
        lightest: "#fff9ea",
        lighter: "#ffd66c",
        light: "#ffca3b",
        default: "#d98d00",
        dark: "#b07301",
        darker: "#7e5400",
        darkest: "#3b2800",
    },
    danger: {
        lightest: "#FFB29A",
        lighter: "#FF7663",
        light: "#EC584A",
        default: "#CA3A31",
        dark: "#CA3A31",
        darker: "#A82727",
        darkest: "#853423",
    },
    score: {
        great: {
            light: "#85ff99",
            default: "#20B174",
            dark: "#3b7745",
        },
        good: {
            light: "#ffa26e",
            default: "#FD8F56",
            dark: "#ff7329",
        },
        bad: {
            light: "#da859a",
            default: "#E25941",
            dark: "#c03b5a",
        }

    },

}
